import cn from 'classnames'
import { useWindowActions } from 'models/window'
import {useEffect, useState} from "react";
import {checkLoadedSelector, checkLoadingSelector, checkSelector} from "../../../models/tasks/selectors";
import Skeleton from "react-loading-skeleton";
import {formatNumbers} from "../../../core/helpers/format-numbers";
import {routes} from "../../../core/routes/paths";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {openWindow} from "../../../models/window/actions";
import {modalNames} from "../../modal/constants/names";
import {checkTask, clearModal} from "../../../models/tasks";
import {userSelector} from "../../../models/user/selectors";
import {Ellipsis} from "../../../ui";

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/taskModal.module.scss'

export const TaskModal = ({ item }) => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }
    const { closeWindow } = useWindowActions()
    const checkLoading = useSelector(checkLoadingSelector)
    const checkLoaded = useSelector(checkLoadedSelector)
    const check = useSelector(checkSelector)
    const [count, setCount] = useState(0)

    const handleClickClose = () => {
        closeWindow()
    }

    const openInvite = () => {
        closeWindow()
        dispatch(openWindow(modalNames.INVITE))
    }

    const openProfile = () => {
        closeWindow()
        dispatch(openWindow(modalNames.SETTINGS))
    }

    const openWithdraw = () => {
        closeWindow()
        dispatch(openWindow(modalNames.WITHDRAW))
    }

    const handleCheck = () => {
        if (!check && !checkLoading) {
            dispatch(checkTask(params, user.telegram_id, item.id))
        }
    }

    const getGoButton = () => {
        if (item.type === 'SUBSCRIBE_WORLDEGGIO' || item.type === 'SUBSCRIBE_PLGBET') {
            return (
                <a
                    href={item.channel_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={c.go}
                >
                    <span>Go</span>
                </a>
            )
        }

        if (item.type === 'OPEN_BOXES') {
            return (
                <Link
                    to={routes.holders}
                    className={c.go}
                    onClick={handleClickClose}
                >
                    <span>Go</span>
                </Link>
            )
        }

        if (item.type === 'INVITE_FRIENDS') {
            return (
                <button
                    className={c.go}
                    onClick={openInvite}
                >
                    <span>Go</span>
                </button>
            )
        }

        if (item.type === 'CONNECT_WALLET') {
            return (
                <button
                    className={c.go}
                    onClick={openProfile}
                >
                    <span>Go</span>
                </button>
            )
        }

        if (item.type === 'WITHDRAW_EGGS') {
            return (
                <button
                    className={c.go}
                    onClick={openWithdraw}
                >
                    <span>Go</span>
                </button>
            )
        }
    }

    const getTitle = () => {
        if (!check && !checkLoading) {
            return (
                <span className={c.title}>{item.title}</span>
            )
        }

        if (checkLoading) {
            return (
                <span className={c.title}>
                    <Skeleton baseColor="#6b6964" width={250} />
                </span>
            )
        }

        if (check && !check.success) {
            return (
                <span className={c.title}>
                    {check.message}
                </span>
            )
        }

        if (check && checkLoaded) {
            return (
                <span className={c.title}>
                    {check.message}
                </span>
            )
        }

        return null
    }

    const getDescription = () => {
        if (!check && !checkLoading) {
            return (
                <span className={c.description}>
                    You will get <strong>{formatNumbers(item.reward_amount)} EGGS</strong>
                </span>
            )
        }

        if (checkLoading) {
            return (
                <span className={c.description}>
                    <Skeleton baseColor="#6b6964" width={200} />
                </span>
            )
        }

        if (check && checkLoaded && check.completed) {
            return (
                <span className={c.description}>
                    Try to complete another task!
                </span>
            )
        }

        if (check && checkLoaded && check.subscribe) {
            return (
                <span className={c.description}>
                    Press «Go» button to subscribe and try again
                </span>
            )
        }

        if (check && checkLoaded && check.current) {
            return (
                <span className={c.description}>
                    <strong>{formatNumbers(check.current)} / {formatNumbers(check.goal)}</strong>{' '}
                    {item.type === 'OPEN_BOXES' && 'boxes opened'}
                    {item.type === 'WITHDRAW_EGGS' && 'EGGS have been withdrawn'}
                    {item.type === 'INVITE_FRIENDS' && 'friends invited'}
                </span>
            )
        }

        if (check && checkLoaded && check.success) {
            return (
                <span className={c.description}>
                    You earned <strong>{formatNumbers(Math.round(count))} EGGS</strong>
                </span>
            )
        }

        return null
    }

    useEffect(() => {
        if (check && check.success && check.reward > 0) {
            const duration = 3000
            const startTime = performance.now()

            const animate = (time) => {
                const elapsedTime = time - startTime
                const progress = Math.min(elapsedTime / duration, 1)
                setCount(check.reward * easeOutCubic(progress))

                if (progress < 1) {
                    requestAnimationFrame(animate)
                }
            }

            requestAnimationFrame(animate)
        }
    }, [check])

    const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3)

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon/>
            </button>
            <div className={c.top}>
                <div className={cn(c.icon, {
                    [c.fail]: check && !check.success,
                    [c.done]: check && check.success,
                    [c.easy]: item.difficulty === 1,
                    [c.medium]: item.difficulty === 2,
                    [c.hard]: item.difficulty === 3,
                    [c.worldEgg]: item.type === 'SUBSCRIBE_WORLDEGGIO',
                    [c.plgBet]: item.type === 'SUBSCRIBE_PLGBET',
                    [c.friends]: item.type === 'INVITE_FRIENDS',
                    [c.boxes]: item.type === 'OPEN_BOXES',
                    [c.wallet]: item.type === 'CONNECT_WALLET' || item.type === 'WITHDRAW_EGGS',
                })} />
                <div className={c.text}>
                    {getTitle()}
                    {getDescription()}
                </div>
            </div>
            <div className={c.buttons}>
                {getGoButton()}
                <button
                    className={cn(c.check, {
                        [c.loading]: checkLoading,
                        [c.disabled]: check && checkLoaded,
                    })}
                    onClick={handleCheck}>
                    {!check ? <span>Check</span> : <span>Done</span>}
                    <div className={c.loaderWrapper}>
                        <Ellipsis className={c.loader}/>
                    </div>
                </button>
            </div>
        </div>
    )
}
