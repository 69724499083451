import { createSelector } from 'reselect'
import { moduleName } from './constants'

const state = (state) => state[moduleName]

export const isTasksLoadingSelector = createSelector(
    state,
    ({ isTasksLoading }) => isTasksLoading
)

export const isTasksLoadedSelector = createSelector(
    state,
    ({ isTasksLoaded }) => isTasksLoaded
)

export const tasksSelector = createSelector(
    state,
    ({ tasks }) => tasks
)

export const checkLoadingSelector = createSelector(
    state,
    ({ checkLoading }) => checkLoading
)

export const checkLoadedSelector = createSelector(
    state,
    ({ checkLoaded }) => checkLoaded
)

export const checkSelector = createSelector(
    state,
    ({ check }) => check
)
