import { useEffect } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { openWindow } from 'models/window/actions'
import { userSelector } from 'models/user/selectors'
import { checkGems } from 'models/gems'
import {
    gemsSelector,
    isCheckLoadedSelector,
    isCheckLoadingSelector,
    isGemsAttemptsLoadedSelector,
    gemsAttemptsSelector,
    isAlreadyOpenedSelector,
    attemptResetSelector,
} from 'models/gems/selectors'
import { modalNames } from 'features/modal/constants/names'
import { Ellipsis } from 'ui'
import { Timer } from './timer'
import { UserTimer } from './user-timer'

import c from './assets/sass/checkGems.module.scss'

export const CheckGems = ({ targetId }) => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }
    const gems = useSelector(gemsSelector)
    const isCheckLoading = useSelector(isCheckLoadingSelector)
    const isCheckLoaded = useSelector(isCheckLoadedSelector)
    const isGemsAttemptsLoaded = useSelector(isGemsAttemptsLoadedSelector)
    const gemsAttempts = useSelector(gemsAttemptsSelector)
    const currentTime = new Date()
    const resetTime = gemsAttempts?.reset_attempts
        ? new Date(gemsAttempts.reset_attempts)
        : null
    const isDisabled =
        gemsAttempts?.daily_attempts === 10 &&
        resetTime &&
        currentTime < resetTime
    const alreadyOpened = useSelector(isAlreadyOpenedSelector)
    const attemptReset = useSelector(attemptResetSelector)

    useEffect(() => {
        if (gems && isCheckLoaded) {
            dispatch(openWindow(modalNames.GEMS, { gems }))
        }
    }, [dispatch, gems, isCheckLoaded])

    const buttonHandler = () => {
        if (!isDisabled && !isCheckLoading && isGemsAttemptsLoaded) {
            dispatch(checkGems(params, user.telegram_id, targetId))
        }
    }

    const openGemsInfo = () => {
        dispatch(openWindow(modalNames.GEMS_INFO))
    }

    const getAttempts = () => {
        if (isGemsAttemptsLoaded && gemsAttempts.daily_attempts >= 20) {
            return (
                <span className={c.attempts}>
                    You've used all <strong>20</strong> attempts today
                </span>
            )
        }

        if (isGemsAttemptsLoaded && gemsAttempts) {
            return (
                <span className={c.attempts}>
                    Available attempts today:{' '}
                    <strong>{gemsAttempts.daily_attempts || 0}/20</strong>
                </span>
            )
        }

        return null
    }

    const getContent = () => {
        if (alreadyOpened) {
            return (
                <div className={cn(c.row, c.alreadyOpened)}>
                    You’ve already opened the gems on this user’s page today.
                    {attemptReset && <UserTimer resetDate={attemptReset} />}
                </div>
            )
        }

        return (
            <div className={c.row}>
                {isDisabled && isGemsAttemptsLoaded && (
                    <div className={c.timer}>
                        <Timer resetDate={gemsAttempts.reset_attempts} />
                    </div>
                )}
                <button
                    className={cn(c.button, {
                        [c.loading]: isCheckLoading,
                        [c.disabled]: isDisabled,
                    })}
                    onClick={buttonHandler}
                    disabled={isDisabled || isCheckLoading}
                >
                    <span className={c.title}>Check Gems</span>
                    <div className={c.loaderWrapper}>
                        {isCheckLoading && <Ellipsis className={c.loader} />}
                    </div>
                </button>
                <div className={c.attemptsRow} onClick={openGemsInfo}>
                    {getAttempts()}
                </div>
            </div>
        )
    }

    return <div className={c.component}>{getContent()}</div>
}
