import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isChiefSelector, userSelector} from "../../models/user/selectors";
import { Table } from 'features/tasks/table'
import {getTasks, clear} from "../../models/tasks";

import c from './assets/sass/page.module.scss'

const TasksPage = () => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }
    const isChief = useSelector(isChiefSelector)

    useEffect(() => {
        dispatch(getTasks(params, user.telegram_id))

        return () => {
            dispatch(clear())
        }
    }, [dispatch])

    if (!isChief) {
        return null
    }

    return (
        <div className={c.component}>
            <div className={c.top}>
                <span className={c.title}>Tasks</span>
                <span className={c.subtitle}>Complete tasks and earn EGGS</span>
            </div>
            <div className={c.middle}>
                <Table />
            </div>
        </div>
    )
}

export default TasksPage
