import Skeleton from 'react-loading-skeleton'

import c from './assets/sass/itemSkeleton.module.scss'

export const ItemSkeleton = () => {
    return (
        <li className={c.component}>
            <span className={c.side}>
                <Skeleton width="200px" baseColor="#4F4A4A" />
            </span>
        </li>
    )
}
