import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addressesSelector, isLoadingSelector } from 'models/holders/selectors'
import { TableItem } from '../table-item'
import { ItemSkeleton } from '../item-skeleton'
import { getHolders } from 'models/holders/actions'

import c from './assets/sass/holdersTable.module.scss'

export const HoldersTable = () => {
    const dispatch = useDispatch()
    const [maxRefHeight, setMaxRefHeight] = useState(0)
    const isLoading = useSelector(isLoadingSelector)
    const addresses = useSelector(addressesSelector)

    useEffect(() => {
        dispatch(getHolders())
    }, [dispatch])

    useEffect(() => {
        const height = document.documentElement.clientHeight - 250
        setMaxRefHeight(height)

        const handleResize = () => {
            const height = document.documentElement.clientHeight - 250
            setMaxRefHeight(height)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const renderContent = () => {
        if (isLoading) {
            return Array.from({ length: 30 }).map((_, index) => (
                <ItemSkeleton key={index} />
            ))
        }

        if (addresses.toJS().length < 1) {
            return (
                <div className={c.empty}>
                    <span>
                        No results were found. Try a little bit later or reset
                        filters!
                    </span>
                </div>
            )
        }

        return addresses
            .slice(0, 100)
            .map((holder, index) => (
                <TableItem key={index} type="holder" holder={holder} />
            ))
    }

    return (
        <div className={c.component}>
            <div
                className={c.wrapper}
                style={{ maxHeight: `${maxRefHeight}px` }}
            >
                <ul className={c.list}>{renderContent()}</ul>
            </div>
        </div>
    )
}
