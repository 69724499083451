import { useEffect, useState, useRef } from 'react';
import cn from 'classnames'
import { Item } from "../item";
import { useSelector } from "react-redux";
import { isTasksLoadedSelector, isTasksLoadingSelector, tasksSelector } from "../../../models/tasks/selectors";
import { ItemSkeleton } from "../item-skeleton";

import c from './assets/sass/table.module.scss';

export const Table = () => {
    const isTasksLoading = useSelector(isTasksLoadingSelector);
    const isTasksLoaded = useSelector(isTasksLoadedSelector);
    const tasks = useSelector(tasksSelector);
    const [maxRefHeight, setMaxRefHeight] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const [isFullScrolled, setIsFullScrolled] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const height = document.documentElement.clientHeight - 130;
        setMaxRefHeight(height);

        const handleResize = () => {
            const height = document.documentElement.clientHeight - 130;
            setMaxRefHeight(height);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (!wrapperRef.current) return;
            const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;

            setIsScrolling(scrollTop > 0);
            setIsFullScrolled(scrollTop + clientHeight >= scrollHeight - 1);
        };

        const wrapper = wrapperRef.current;
        if (wrapper) {
            wrapper.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (wrapper) {
                wrapper.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const renderContent = () => {
        if (isTasksLoading) {
            return Array.from({ length: 30 }).map((_, index) => (
                <ItemSkeleton key={index} />
            ));
        }

        if (isTasksLoaded) {
            return tasks.map((item, index) => <Item item={item} key={index} />);
        }
    };

    return (
        <div
            className={cn(c.component, {
                [c.scrolling]: isScrolling,
                [c.scrolled]: isFullScrolled,
            })}
        >
            <div className={c.shadows}>
                <div className={c.shadow}/>
                <div className={c.shadow}/>
            </div>
            <div
                ref={wrapperRef}
                className={c.wrapper}
                style={{height: `${maxRefHeight}px`}}
            >
                <ul className={c.list}>{renderContent()}</ul>
            </div>
        </div>
    );
};
