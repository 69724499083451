import {
    GET_TASKS_START,
    GET_TASKS_SUCCESS,
    GET_TASKS_ERROR,
    CHECK_TASK_START,
    CHECK_TASK_SUCCESS,
    CHECK_TASK_ERROR,
    UPDATE_TASKS,
    CLEAR_MODAL,
    CLEAR,
} from './constants'
import { tasksApi } from 'api/tasks'

export const getTasks = (initData, telegram_id) => (dispatch) => {
    dispatch({ type: GET_TASKS_START })

    tasksApi
        .getTasks(initData, telegram_id)
        .then((response) =>
            dispatch({
                type: GET_TASKS_SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: GET_TASKS_ERROR })
        })
}

export const checkTask =
    (initData, telegram_id, task_id) => (dispatch) => {
        dispatch({ type: CHECK_TASK_START })

        tasksApi
            .checkTask(initData, telegram_id, task_id)
            .then((response) =>
                dispatch({
                    type: CHECK_TASK_SUCCESS,
                    payload: response.data,
                })
            )
            .catch(() => {
                dispatch({ type: CHECK_TASK_ERROR })
            })
    }

export const updateTasks = (payload) => ({
    type: UPDATE_TASKS,
    payload: payload,
})

export const clearModal = () => ({
    type: CLEAR_MODAL,
})

export const clear = () => ({
    type: CLEAR,
})
