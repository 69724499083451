export const moduleName = 'tasks'

export const GET_TASKS_START = `${moduleName}/GET_TASKS_START`
export const GET_TASKS_SUCCESS = `${moduleName}/GET_TASKS_SUCCESS`
export const GET_TASKS_ERROR = `${moduleName}/GET_TASKS_ERROR`

export const CHECK_TASK_START = `${moduleName}/CHECK_TASK_START`
export const CHECK_TASK_SUCCESS = `${moduleName}/CHECK_TASK_SUCCESS`
export const CHECK_TASK_ERROR = `${moduleName}/CHECK_TASK_ERROR`

export const UPDATE_TASKS = `${moduleName}/UPDATE_TASKS`

export const CLEAR = `${moduleName}/CLEAR`
export const CLEAR_MODAL = `${moduleName}/CLEAR_MODAL`
