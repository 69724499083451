import { openWindow } from 'models/window/actions'
import { modalNames } from 'features/modal/constants/names'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getMcap } from 'models/mcap/actions'
import { isLoadingSelector, priceSelector } from 'models/mcap/selectors'
import { formatNumbers } from 'core/helpers/format-numbers'
import Skeleton from 'react-loading-skeleton'
import { Balance } from '../balance'

import c from './assets/sass/mcap.module.scss'

export const Mcap = () => {
    const dispatch = useDispatch()
    const price = useSelector(priceSelector)
    const isLoading = useSelector(isLoadingSelector)

    useEffect(() => {
        dispatch(getMcap())
    }, [dispatch])

    const openFaq = () => {
        dispatch(openWindow(modalNames.FAQ))
    }

    const getValue = () => {
        if (isLoading || !price) {
            return <Skeleton baseColor="#2b2929" />
        }

        if (price) {
            const totalCapitalization = Math.floor(price.toFixed(9) * 1000000000)

            return `$${formatNumbers(totalCapitalization)}`
        }
    }

    return (
        <div className={c.component}>
            <span className={c.counter}>{getValue()}</span>
            <span className={c.title} onClick={openFaq}>
                MCAP
            </span>
            <Balance />
        </div>
    )
}
