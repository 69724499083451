import { Record } from 'immutable'
import {
    GET_TASKS_START,
    GET_TASKS_SUCCESS,
    GET_TASKS_ERROR,
    CHECK_TASK_START,
    CHECK_TASK_SUCCESS,
    CHECK_TASK_ERROR,
    UPDATE_TASKS,
    CLEAR_MODAL,
    CLEAR,
} from './constants'

const TasksRecord = Record({
    isTasksLoading: false,
    isTasksLoaded: false,
    checkLoading: false,
    checkLoaded: false,
    check: null,
    tasks: null,
})

const defaultState = new TasksRecord()

const reducer = (state = new TasksRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case GET_TASKS_START:
            return state.set('isTasksLoading', true)

        case GET_TASKS_SUCCESS:
            return state
                .set('tasks', payload)
                .set('isTasksLoaded', true)
                .set('isTasksLoading', false)

        case GET_TASKS_ERROR:
            return state
                .set('isTasksLoaded', false)
                .set('isTasksLoading', false)

        case CHECK_TASK_START:
            return state
                .set('checkLoading', true)

        case CHECK_TASK_SUCCESS:
            return state
                .set('check', payload)
                .set('checkLoaded', true)
                .set('checkLoading', false)

        case CHECK_TASK_ERROR:
            return state
                .set('checkLoaded', false)
                .set('checkLoading', false)

        case UPDATE_TASKS:
            return state
                .set('tasks', payload?.tasks)

        case CLEAR_MODAL:
            console.log('clear modal')
            return state
                .set('check', null)
                .set('checkLoaded', false)
                .set('checkLoading', false)

        case CLEAR:
            return defaultState

        default:
            return state
    }
}

export default reducer
