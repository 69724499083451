import { useSelector } from 'react-redux'
import {
    balanceSelector,
    isBalanceLoadingSelector,
    userSelector,
} from 'models/user/selectors'
import Skeleton from 'react-loading-skeleton'
import { formatNumbers } from 'core/helpers/format-numbers'
import { priceSelector } from 'models/mcap/selectors'

import c from './assets/sass/balance.module.scss'

export const Balance = () => {
    const user = useSelector(userSelector)
    const isLoading = useSelector(isBalanceLoadingSelector)
    const balance = useSelector(balanceSelector)
    const price = useSelector(priceSelector)
    const totalBalance =
        parseFloat((balance / 1000000000).toFixed(2)) +
        parseFloat(user.available_eggs || 0) +
        parseFloat(user.earned_gems || 0)
    const totalUsd = (totalBalance * price).toFixed(2)

    if (isLoading || !user.earned_eggs) {
        return (
            <span className={c.component}>
                <strong>
                    <Skeleton baseColor="#474747" />
                </strong>
            </span>
        )
    }

    if (!user.earned_eggs) {
        return (
            <span className={c.component}>
                <strong>0 EGGS</strong>
            </span>
        )
    }

    return (
        <span className={c.component}>
            <strong>{formatNumbers(Math.floor(totalBalance))} EGGS</strong> (${' '}
            {formatNumbers(totalUsd)})
        </span>
    )
}
