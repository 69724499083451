import cn from 'classnames'
import {formatNumbers} from "core/helpers/format-numbers";
import {openWindow} from "../../../models/window/actions";
import {modalNames} from "../../modal/constants/names";
import {useDispatch} from "react-redux";
import {clearModal} from "../../../models/tasks";

import { ReactComponent as Arrow } from './assets/images/arrow.svg'
import { ReactComponent as Done } from './assets/images/done-icon.svg'

import c from './assets/sass/item.module.scss'

export const Item = ({ item }) => {
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(clearModal())
        if (!item.is_completed) {
            dispatch(openWindow(modalNames.TASK, { item }))
        }
    }

    return (
        <div className={cn(c.component, {
            [c.done]: item.is_completed,
            [c.worldEgg]: item.type === 'SUBSCRIBE_WORLDEGGIO',
            [c.plgBet]: item.type === 'SUBSCRIBE_PLGBET',
            [c.easy]: item.difficulty === 1,
            [c.medium]: item.difficulty === 2,
            [c.hard]: item.difficulty === 3,
            [c.friends]: item.type === 'INVITE_FRIENDS',
            [c.boxes]: item.type === 'OPEN_BOXES',
            [c.wallet]: item.type === 'CONNECT_WALLET' || item.type === 'WITHDRAW_EGGS',
        })}
             onClick={handleClick}
        >
            <div className={c.description}>
                <span className={c.title}>{item.title}</span>
                <span className={c.reward}>+{formatNumbers(item.reward_amount)}</span>
            </div>
            {item.is_completed ? <Done className={c.icon} /> : <Arrow className={c.icon} />}
        </div>
    )
}
