import { useWindowActions } from 'models/window'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/gemsInfoModal.module.scss'

export const GemsInfoModal = () => {
    const { closeWindow } = useWindowActions()

    const handleClickClose = () => {
        closeWindow()
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>Meet the Gems</span>
                <span className={c.subtitle}>Introducing our loot system</span>
            </div>
            <ul className={c.list}>
                <li className={c.item}>
                    <span className={c.itemTitle}>What are gems?</span>
                    <p className={c.text}>
                        Gems are our internal in-game currency that you can earn
                        every day.
                    </p>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>How does it work?</span>
                    <p className={c.text}>
                        To get gems, you need to visit any user profile and
                        click the «Check Gems» button. After that, with some
                        chance, you may receive from 0 to 250 gems per opening.
                        Each profile can only be opened once.
                    </p>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>
                        How many attempts are there?
                    </span>
                    <p className={c.text}>
                        Each user is given 20 attempts, after which a 24-hour
                        timer starts. Once this time has passed, you will have
                        20 attempts available again.
                    </p>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>How to withdraw gems?</span>
                    <p className={c.text}>
                        1 GEM equals 1 EGG. Immediately after receiving the
                        gems, they become available for withdrawal in your
                        profile. It's that simple!
                    </p>
                </li>
            </ul>
        </div>
    )
}
