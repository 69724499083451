import cn from 'classnames'
import { useWindowActions } from 'models/window'
import { useState, useEffect } from 'react'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/gemsModal.module.scss'

export const GemsModal = ({ gems }) => {
    const { closeWindow } = useWindowActions()
    const [count, setCount] = useState(0)

    const handleClickClose = () => {
        closeWindow()
    }

    const getSubtitle = () => {
        if (gems.success) {
            return (
                <p className={c.subtitle}>
                    You just collected <strong>{Math.round(count)} EGGS</strong>
                </p>
            )
        }

        return <p className={c.subtitle}>You will be lucky next time!</p>
    }

    useEffect(() => {
        if (gems.success && gems.gems > 0) {
            const duration = 2000
            const startTime = performance.now()

            const animate = (time) => {
                const elapsedTime = time - startTime
                const progress = Math.min(elapsedTime / duration, 1)
                setCount(gems.gems * easeOutCubic(progress))

                if (progress < 1) {
                    requestAnimationFrame(animate)
                }
            }

            requestAnimationFrame(animate)
        }
    }, [gems.success, gems.gems])

    const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3)

    return (
        <div
            className={cn(c.component, {
                [c.win]: gems.success,
            })}
        >
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>
                    {gems.success ? 'Congratulations!' : 'Sorry'}
                </span>
                {getSubtitle()}
            </div>
            <button className={c.button} onClick={handleClickClose}>
                Thanks!
            </button>
        </div>
    )
}
