import axios from 'core/http'

const root = 'https://wr-egg.com/api/tasks'

export const tasksApi = {
    getTasks: (initData, telegram_id) =>
        axios.post(`${root}/${telegram_id}`, { initData }),

    checkTask: (initData, telegram_id, task_id) =>
        axios.post(`${root}/check/${telegram_id}`, {
            initData,
            task_id,
        }),
}
